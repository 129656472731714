import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getValidationSchema,
  handleErrorMessage,
  roleData,
} from "../../../../validation/profileData.validation";
import { useAuth0 } from "@auth0/auth0-react";
import useAccountProgressAPI from "../../../../hooks/useGetAccountProgressAPI";
import {
  AWARENESS_CHANNELS,
  FIELDS_INTEREST,
  JOB_STATES,
  STUDY_YEARS,
} from "../../../../utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { users } from "../../../../services/api/models";
import logo from "../../../../images/cq-small-logo.png";
import { loginConfirmedAction } from "../../../../store/actions/AuthActions";

const fieldGroups = {
  interestFieldsAndLinkedinGroup: ["interestFields", "linkedinProfileLink"],
  companyGroup: ["companyName", "experienceYears", "jobState"],
  educationGroup: ["university", "faculty"],
  locationGroup: ["country", "city"],
};
function CompleteAccountModal({ setOpenModal }) {
  const divRef = useRef(null);
  const awarenessChannelsRef = useRef(null);
  const { auth } = useSelector((state) => state.auth);
  const { user, getAccessTokenSilently } = useAuth0();
  const { missingFields } = useAccountProgressAPI();
  const dispatch = useDispatch();

  const [clickLoading, setClickLoading] = useState(false);
  const [openInterestFields, setOpenInterestFields] = useState(false);
  const [openAwarenessChannels, setOpenAwarenessChannels] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getValidationSchema(false, true)),
    defaultValues: {
      studyYear: "",
      jobState: "",
      interestFields: [],
      where_you_know_us: [],
      otherAwarenessChannel: "",
    },
    context: {
      submissionContext: true,
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (auth.user) {
      for (const key in auth.user) {
        if (key === "interestFields" && typeof auth.user[key] === "string") {
          let interestedArray = [];

          // Check if interestFields is not an empty string
          if (auth.user.interestFields.trim() !== "") {
            // Split the string into an array and trim each field
            interestedArray = auth.user.interestFields
              .split(", ")
              .map((field) => field.trim());
          }
          setValue(key, interestedArray);
        } else if (
          key === "where_you_know_us" &&
          typeof auth.user[key] === "string"
        ) {
          let whereYouKnowUsArray = [];

          // Check if where_you_know_us is not an empty string
          if (auth.user.where_you_know_us.trim() !== "") {
            // Split the string into an array and trim each field
            whereYouKnowUsArray = auth.user.where_you_know_us
              .split(", ")
              .map((field) => field.trim());
          }
          setValue(key, whereYouKnowUsArray);
        } else {
          setValue(key, auth.user[key] || "");
        }
      }
    }
  }, [auth.user, setValue]);

  const studyYear = watch("studyYear");
  const whereYouKnowUs = watch("where_you_know_us");
  const isOtherSelected = whereYouKnowUs?.includes("Other");

  const onSubmit = async (formData) => {
    let finalWhereYouKnowUs = [
      ...formData.where_you_know_us,
      ...(formData.otherAwarenessChannel
        ? [formData.otherAwarenessChannel]
        : []),
    ];
    finalWhereYouKnowUs = finalWhereYouKnowUs.filter(
      (value) => value !== "Other"
    );

    const newData = roleData({
      ...formData,
      where_you_know_us: finalWhereYouKnowUs,
    });
    setClickLoading(true);
    try {
      // console.log(requestBody)
      const accessToken = await getAccessTokenSilently();
      const headers = { Authorization: "Bearer " + accessToken };
      let Options = { headers: headers };
      const userFilter = { auth0Id: user.sub };

      const response = await users.usersPatch(newData, userFilter, Options);
      // console.log("edit user data in db : ", response.data);

      // merge the two users to get all data
      if (response.status === 200 && response.data.length !== 0) {
        toast.success("Success update profile");
        setOpenModal(false);
        const dbUserData = response.data[0];

        // Dynamically merge user and dbUserData, prioritizing dbUserData values
        const mergedUser = {
          ...user,
          ...Object.keys(dbUserData).reduce((acc, key) => {
            // ensures the key belongs directly to dbUserData (not inherited from the prototype chain)
            if (dbUserData.hasOwnProperty(key)) {
              acc[key] = dbUserData[key] ?? user[key] ?? "";
            }
            return acc;
          }, {}),
        };
        dispatch(loginConfirmedAction({ user: mergedUser, accessToken }));
      }
    } catch (err) {
      if (err.response) {
        const message = handleErrorMessage(err.response.data.message);
        toast.error(message);
      } else {
        toast.error(err.message || "Can't Edit Account Now, Try Another Time.");
      }
      console.log("Error in updating user data", err);
    }
    setClickLoading(false);
  };

  // Handle changes for where_you_know_us
  const handleWhereYouKnowUsChange = (event) => {
    const value = event.target.value;
    const currentValues = watch("where_you_know_us") || [];
    let newWhereYouKnowUs;

    if (event.target.checked) {
      newWhereYouKnowUs = [...currentValues, value];
    } else {
      newWhereYouKnowUs = currentValues.filter((field) => field !== value);
    }

    setValue("where_you_know_us", newWhereYouKnowUs); // Update form value
  };

  // Handle click outside
  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setOpenInterestFields(false);
    }
  };
  const handleAwarenessChannelsClickOutside = (event) => {
    if (
      awarenessChannelsRef.current &&
      !awarenessChannelsRef.current.contains(event.target)
    ) {
      setOpenAwarenessChannels(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleAwarenessChannelsClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener(
        "mousedown",
        handleAwarenessChannelsClickOutside
      );
    };
  }, []);

  const getColumnWidth = (missingFields, fieldGroup) => {
    const missingCount = missingFields.filter((f) =>
      fieldGroup.includes(f)
    ).length;

    // For a group of 2 fields
    if (fieldGroup.length === 2) {
      if (missingCount === 1) {
        return "col-md-12";
      } else if (missingCount === 2) {
        return "col-md-6";
      }
    }

    // For a group of 3 fields
    else if (fieldGroup.length === 3) {
      if (missingCount === 1) {
        return "col-md-12";
      } else if (missingCount === 2) {
        return "col-md-6";
      } else if (missingCount === 3) {
        return "col-md-4";
      }
    }

    // Default to col-md-4 if no fields are missing (fallback)
    return "col-md-4";
  };

  return (
    <div>
      <div className="modal-open">
        <div
          className="modal"
          style={{ display: "block" }}
          role="dialog"
          tabIndex="-1"
        >
          <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
            <div className="d-flex justify-content-center h-100 align-items-center">
              <div
                className="authincation-content style-2"
                style={{ maxWidth: "47rem" }}
              >
                <div className="row no-gutters">
                  <div className="col-xl-12 tab-content position-relative">
                    <div
                      id="sign-in"
                      className="auth-form form-validation bg-white"
                      style={{ borderRadius: "2%", fontSize: "11px" }}
                    >
                      <form
                        className="form-validate step-form-horizontal"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div
                          style={{ height: "60px" }}
                          className=" mb-5 mt-2 d-flex justify-content-center align-items-center flex-column"
                        >
                          <img src={logo} alt="logo" className="h-100 mb-2" />
                          <h3 className="text-primary font-w600">
                            Complete Your Account
                          </h3>
                        </div>

                        <>
                          <div className="row">
                            {missingFields.includes("interestFields") && (
                              <div
                                className={`form-group mb-3 ${getColumnWidth(
                                  missingFields,
                                  fieldGroups.interestFieldsAndLinkedinGroup
                                )}`}
                              >
                                <label className="form-label font-w600">
                                  Interest Fields
                                </label>
                                <div
                                  className="form-control d-flex align-items-center mb-1"
                                  onClick={() =>
                                    setOpenInterestFields(!openInterestFields)
                                  }
                                >
                                  {watch("interestFields").length === 0
                                    ? "Choose..."
                                    : watch("interestFields").join(", ")}
                                </div>
                                {openInterestFields && (
                                  <div
                                    className="form-control h-100 p-4"
                                    ref={divRef}
                                  >
                                    {FIELDS_INTEREST.map((val, index) => (
                                      <div key={index} className="form-check">
                                        <input
                                          type="checkbox"
                                          value={val}
                                          id={`checkbox-${index}`}
                                          className="form-check-input"
                                          checked={watch(
                                            "interestFields"
                                          ).includes(val)}
                                          {...register("interestFields")}
                                        />
                                        <label
                                          htmlFor={`checkbox-${index}`}
                                          className="form-check-label"
                                        >
                                          {val}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {errors && errors.interestFields && (
                                  <div className="text-danger fs-12">
                                    {" "}
                                    {errors.interestFields.message}{" "}
                                  </div>
                                )}
                              </div>
                            )}
                            {missingFields.includes("linkedinProfileLink") && (
                              <div
                                className={`form-group mb-3 ${getColumnWidth(
                                  missingFields,
                                  fieldGroups.interestFieldsAndLinkedinGroup
                                )}`}
                              >
                                <label className="form-label font-w600">
                                  Linkedin Profile
                                </label>
                                <input
                                  {...register("linkedinProfileLink")}
                                  type="text"
                                  className="form-control mb-1"
                                  placeholder="Link"
                                />
                                {errors && errors.linkedinProfileLink && (
                                  <div className="text-danger fs-12">
                                    {errors.linkedinProfileLink.message}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          {user.role === "contestant" &&
                            missingFields.includes("studyYear") && (
                              <div className="form-group mb-3">
                                <label className="form-label font-w600">
                                  Study Year
                                </label>
                                <select
                                  {...register("studyYear")}
                                  className="form-select form-control solid mb-1"
                                >
                                  <option value="" disabled>
                                    Choose...
                                  </option>
                                  {STUDY_YEARS.map((val, index) => (
                                    <option key={index} value={val}>
                                      {val}
                                    </option>
                                  ))}
                                </select>
                                {errors && errors.studyYear && (
                                  <div className="text-danger fs-12">
                                    {errors.studyYear.message}
                                  </div>
                                )}
                              </div>
                            )}

                          {user.role === "contestant" &&
                            studyYear !== "Graduate" &&
                            studyYear !== "" && (
                              <div className="row">
                                {missingFields.includes("university") && (
                                  <div
                                    className={`form-group mb-3 ${getColumnWidth(
                                      missingFields,
                                      fieldGroups.educationGroup
                                    )}`}
                                  >
                                    <label className="form-label font-w600">
                                      University
                                    </label>
                                    <input
                                      {...register("university")}
                                      type="text"
                                      className="form-control mb-1"
                                      placeholder="Type Your University"
                                    />
                                    {errors && errors.university && (
                                      <div className="text-danger fs-12">
                                        {" "}
                                        {errors.university.message}{" "}
                                      </div>
                                    )}
                                  </div>
                                )}

                                {missingFields.includes("faculty") && (
                                  <div
                                    className={`form-group mb-3 ${getColumnWidth(
                                      missingFields,
                                      fieldGroups.educationGroup
                                    )}`}
                                  >
                                    <label className="form-label font-w600">
                                      Faculty
                                    </label>
                                    <input
                                      {...register("faculty")}
                                      type="text"
                                      className="form-control mb-1"
                                      placeholder="Type Your Faculty"
                                    />
                                    {errors && errors.faculty && (
                                      <div className="text-danger fs-12">
                                        {" "}
                                        {errors.faculty.message}{" "}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}

                          {((user.role === "contestant" &&
                            studyYear === "Graduate") ||
                            user.role === "reviewer") && (
                            <div
                              className={user.role !== "client" ? "row" : ""}
                            >
                              {missingFields.includes("companyName") && (
                                <div
                                  className={`form-group mb-3 
                                        ${getColumnWidth(
                                          missingFields,
                                          fieldGroups.companyGroup
                                        )}`}
                                >
                                  <label className="form-label font-w600">
                                    Company Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control mb-1"
                                    placeholder="Type Your Coumpany"
                                    {...register("companyName")}
                                  />
                                  {errors && errors.companyName && (
                                    <div className="text-danger fs-12">
                                      {" "}
                                      {errors.companyName.message}{" "}
                                    </div>
                                  )}
                                </div>
                              )}

                              {user.role !== "client" && (
                                <>
                                  {missingFields.includes(
                                    "experienceYears"
                                  ) && (
                                    <div
                                      className={`form-group mb-3 ${getColumnWidth(
                                        missingFields,
                                        fieldGroups.companyGroup
                                      )}`}
                                    >
                                      <label className="form-label font-w600">
                                        Experience Years
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control mb-1"
                                        placeholder="Experience Years"
                                        {...register("experienceYears")}
                                      />
                                      {errors && errors.experienceYears && (
                                        <div className="text-danger fs-12">
                                          {" "}
                                          {errors.experienceYears.message}{" "}
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {missingFields.includes("jobState") && (
                                    <div
                                      className={`form-group mb-3 ${getColumnWidth(
                                        missingFields,
                                        fieldGroups.companyGroup
                                      )}`}
                                    >
                                      <label className="form-label font-w600">
                                        Job State
                                      </label>
                                      <select
                                        {...register("jobState")}
                                        className="form-select form-control solid mb-1"
                                      >
                                        <option value="" disabled>
                                          Choose...
                                        </option>
                                        {JOB_STATES.map((val, index) => (
                                          <option key={index} value={val}>
                                            {val}
                                          </option>
                                        ))}
                                      </select>
                                      {errors && errors.jobState && (
                                        <div className="text-danger fs-12">
                                          {" "}
                                          {errors.jobState.message}{" "}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}

                          {user.role !== "client" &&
                            missingFields.includes("where_you_know_us") && (
                              <div className="form-group mb-3">
                                <label className="form-label font-w600">
                                  Where You Know Us
                                </label>
                                <div
                                  className="form-control d-flex align-items-center mb-1"
                                  onClick={() =>
                                    setOpenAwarenessChannels(
                                      !openAwarenessChannels
                                    )
                                  }
                                >
                                  {whereYouKnowUs.length === 0
                                    ? "Choose..."
                                    : whereYouKnowUs.join(", ")}
                                </div>
                                {openAwarenessChannels && (
                                  <div
                                    className="form-control h-100 p-4"
                                    ref={awarenessChannelsRef}
                                  >
                                    {AWARENESS_CHANNELS.map(
                                      (channel, index) => (
                                        <div key={index} className="form-check">
                                          <input
                                            type="checkbox"
                                            value={channel}
                                            id={`checkbox-${index}`}
                                            className="form-check-input"
                                            checked={whereYouKnowUs.includes(
                                              channel
                                            )}
                                            onChange={
                                              handleWhereYouKnowUsChange
                                            }
                                          />
                                          <label
                                            htmlFor={`checkbox-${index}`}
                                            className="form-check-label"
                                          >
                                            {channel}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                                {errors && errors.where_you_know_us && (
                                  <div className="text-danger fs-12">
                                    {" "}
                                    {errors.where_you_know_us.message}{" "}
                                  </div>
                                )}
                              </div>
                            )}

                          {user.role !== "client" &&
                            isOtherSelected &&
                            missingFields.includes("where_you_know_us") && (
                              <div className="form-group mb-3">
                                <label className="form-label font-w600">
                                  Other
                                  <span className="text-danger scale5 ms-2">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control mb-1"
                                  placeholder="Other"
                                  {...register("otherAwarenessChannel")}
                                />

                                {errors && errors.otherAwarenessChannel && (
                                  <div className="text-danger fs-12">
                                    {" "}
                                    {errors.otherAwarenessChannel.message}{" "}
                                  </div>
                                )}
                              </div>
                            )}

                          <div className="row">
                            {missingFields.includes("country") && (
                              <div
                                className={`form-group mb-3 ${getColumnWidth(
                                  missingFields,
                                  fieldGroups.locationGroup
                                )}`}
                              >
                                <label className="form-label font-w600">
                                  Country
                                </label>
                                <input
                                  {...register("country")}
                                  type="text"
                                  className="form-control mb-1"
                                  placeholder="Type Your Country"
                                />
                                {errors && errors.country && (
                                  <div className="text-danger fs-12">
                                    {" "}
                                    {errors.country.message}{" "}
                                  </div>
                                )}
                              </div>
                            )}
                            {missingFields.includes("city") && (
                              <div
                                className={`form-group mb-3 ${getColumnWidth(
                                  missingFields,
                                  fieldGroups.locationGroup
                                )}`}
                              >
                                <label className="form-label font-w600">
                                  City
                                </label>
                                <input
                                  type="text"
                                  className="form-control mb-1"
                                  placeholder="Type Your City"
                                  {...register("city")}
                                />
                                {errors && errors.city && (
                                  <div className="text-danger fs-12">
                                    {" "}
                                    {errors.city.message}{" "}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="form-group mt-4 col-md-6">
                              <button
                                className="btn btn-outline-primary btn-block"
                                type="button"
                                disabled={clickLoading} // Disable button while loading
                                onClick={() => setOpenModal(false)}
                              >
                                {clickLoading ? (
                                  <>
                                    Loading
                                    <span
                                      className="spinner-grow spinner-grow-sm"
                                      style={{ marginLeft: "5px" }}
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Loading...</span>
                                  </>
                                ) : (
                                  <>Back</>
                                )}
                              </button>
                            </div>

                            <div className="form-group mt-4 col-md-6">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled={clickLoading} // Disable button while loading
                              >
                                {clickLoading ? (
                                  <>
                                    Loading
                                    <span
                                      className="spinner-grow spinner-grow-sm"
                                      style={{ marginLeft: "5px" }}
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Loading...</span>
                                  </>
                                ) : (
                                  <>Continue</>
                                )}
                              </button>
                            </div>
                          </div>
                        </>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default CompleteAccountModal;
