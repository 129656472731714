import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import useQuestsAPI from "../../../hooks/useQuestsAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TextField, Slider, FormHelperText } from "@mui/material";
import useQuestSubmissionAPI from "../../../hooks/useQuestSubmissionAPI.js";
import {
  TextFieldCustomization,
  SliderCustomization,
} from "./materialUI customization.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useAccountProgressAPI from "../../../hooks/useGetAccountProgressAPI.js";
import CompleteAccountModal from "./components/CompleteAccountModal.js";

/**
 * QuestRegistration Component
 * Component to show inputs required for submission for a form while also handling the required API requests.
 *
 * @returns {JSX.Element} The rendered component.
 */
const QuestSubmission = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();

  const { progress } = useAccountProgressAPI();
  const isClient = user.role === "client";
  const [openModal, setOpenModal] = useState(false);

  // Initialize react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Get quest data
  const { id } = useParams();
  const { isLoading, data, error } = useQuestsAPI(id);
  // Handle if enter invalid id in url
  const res = error ? error.response : null;
  useEffect(() => {
    if (res && res.status === 404) {
      navigate("/page-error-404");
    }
  }, [res, navigate]);

  // Initialize quest submission custom hook and check if user is registered and approved
  const { questSubmissionRequest, isLoading: isSubmissionLoading } =
    useQuestSubmissionAPI(id);

  // Flag if submission has ended
  const phaseEnded =
    data?.phase !== "submission" || data?.submissionDeadline < new Date();

  // Handle submission
  const onSubmit = async (formData) => {
    if (progress < 100) {
      setOpenModal(true);
      return;
    }
    try {
      await questSubmissionRequest({ ...data, ...formData });

      toast.success("Submitted");
      setTimeout(() => {
        navigate("/quest-list");
      }, 3500);
    } catch (error) {
      console.log(error.message);
      toast.error(
        error.message.charAt(0).toUpperCase() + error.message.slice(1)
      );
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap justify-content-end">
        <Button
          className="me-2"
          variant="outline-primary btn-rounded"
          onClick={() => navigate(`/quest-view/${id}`)}
        >
          <i className="fa-solid fa-chevron-left"></i>{" "}
          <span style={{ margin: "0 10px" }}>Back</span>
        </Button>
      </div>

      <div className="quest-view">
        <div className="card w-100">
          {/* WAIT FOR RESPONSE */}
          {isLoading ? (
            <div className="card-header d-flex align-items-center justify-content-start gap-5 flex-wrap">
              <h3 className="mb-4 me-auto">Loading...</h3>
            </div>
          ) : (
            <>
              <div className="card-header d-flex align-items-center justify-content-start gap-5 flex-wrap">
                <h3>{`${id} - ${data.title} Quest`}</h3>
                {phaseEnded && (
                  <h3 className="color-primary">Submission Phase Ended!</h3>
                )}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* VIDEO & FILES */}
                <div className="card-body">
                  <h3>Submission Files</h3>

                  <div className="text-inputs-width mb-1">
                    <Controller
                      name="projectFilesUrl"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Project Files URL"
                          variant="standard"
                          sx={TextFieldCustomization}
                          fullWidth
                          type="url"
                          error={!!errors.projectFilesUrl}
                          helperText={
                            errors.projectFilesUrl
                              ? "Project Files URL is required"
                              : ""
                          }
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  <p>
                    If you face any problems submitting your files please notify
                    us on the Whatsapp Group Don't include the video in the
                    compressed file to keep it small
                  </p>

                  <div className="text-inputs-width mb-1">
                    <Controller
                      name="videoWalkthroughUrl"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Video Walkthrough URL"
                          variant="standard"
                          sx={TextFieldCustomization}
                          fullWidth
                          type="url"
                          error={!!errors.videoWalkthroughUrl}
                          helperText={
                            errors.videoWalkthroughUrl
                              ? "Video Walkthrough URL is required"
                              : ""
                          }
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  <p>
                    The video of the design page to page (like a prototype
                    walkthrough with navigating through the links explaining the
                    user journey)
                  </p>
                  <div className="text-inputs-width mb-1">
                    <Controller
                      name="submissionNote"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Additional Notes"
                          variant="standard"
                          sx={TextFieldCustomization}
                          fullWidth
                          error={!!errors.submissionNote}
                        />
                      )}
                      rules={{ required: false }}
                    />
                  </div>
                  <p>Any notes you want to leave to your reviewer</p>
                </div>

                {/* FEEDBACK */}
                <div className="card-body pt-0">
                  <h3>Feedback</h3>

                  <div className="feedback-width">
                    <p className="feedback-slider mb-1">
                      What is your rate about the given data?
                    </p>
                    <Controller
                      name="rateData"
                      control={control}
                      defaultValue={5}
                      render={({ field }) => (
                        <Slider
                          {...field}
                          aria-label="rate-data"
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={0}
                          max={10}
                          sx={SliderCustomization}
                        />
                      )}
                    />

                    <div className="form-group mb-4">
                      <Controller
                        name="rateDataReason"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <>
                            <textarea
                              className="form-control"
                              {...field}
                              rows="3"
                              placeholder="Reason for the rating..."
                            ></textarea>
                            {errors.rateDataReason && (
                              <FormHelperText error>
                                This field is required.
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <p className="feedback-slider mb-1">
                      What is your rate for the prizes?
                    </p>
                    <Controller
                      name="ratePrize"
                      control={control}
                      defaultValue={5}
                      render={({ field }) => (
                        <Slider
                          {...field}
                          aria-label="rate-prizes"
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={0}
                          max={10}
                          sx={SliderCustomization}
                        />
                      )}
                    />
                    <div className="form-group mb-4">
                      <Controller
                        name="ratePrizeReason"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <>
                            <textarea
                              className="form-control"
                              {...field}
                              rows="3"
                              placeholder="Reason for the rating..."
                            ></textarea>
                            {errors.ratePrizeReason && (
                              <FormHelperText error>
                                This field is required.
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <p className="feedback-slider mb-1">
                      What is your rate for the group interaction?
                    </p>
                    <Controller
                      name="rateInteraction"
                      control={control}
                      defaultValue={5}
                      render={({ field }) => (
                        <Slider
                          {...field}
                          aria-label="rate-interaction"
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={0}
                          max={10}
                          sx={SliderCustomization}
                        />
                      )}
                    />
                    <div className="form-group mb-4">
                      <Controller
                        name="rateInteractionReason"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <>
                            <textarea
                              className="form-control"
                              {...field}
                              rows="3"
                              placeholder="Reason for the rating..."
                            ></textarea>
                            {errors.rateInteractionReason && (
                              <FormHelperText error>
                                This field is required.
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <p className="feedback-slider mb-1">
                      Do you have any additional feedback comments?
                    </p>
                    <div className="form-group mb-4">
                      <textarea
                        className="form-control"
                        rows="3"
                        name="additionalComments"
                        placeholder="Additional Comments..."
                      ></textarea>
                    </div>
                  </div>

                  <hr />
                </div>

                {/* SUBMIT */}
                <div className="card-footer border-0">
                  {!phaseEnded && (
                    <button
                      className="btn btn-primary px-5 mb-4 fs-14"
                      type="submit"
                      disabled={isSubmissionLoading || isClient}
                    >
                      SUBMIT
                    </button>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>

      {/* Complete Account Modal */}
      {openModal && <CompleteAccountModal setOpenModal={setOpenModal} />}
    </>
  );
};

export default QuestSubmission;
