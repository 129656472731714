import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../images/cq-small-logo.png";
import { users } from "../../services/api/models";
import {
  handleErrorMessage,
  roleData,
  getValidationSchema,
} from "../../validation/profileData.validation";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Step, Stepper } from "react-form-stepper";
import { Button } from "react-bootstrap";
import {
  AWARENESS_CHANNELS,
  FIELDS_INTEREST,
  JOB_STATES,
  POSITIONS,
  ROLES,
  STUDY_YEARS,
} from "../../utils/constants";

function ProfileData() {
  const navigate = useNavigate();
  const { user, loginWithRedirect, getAccessTokenSilently, logout } =
    useAuth0();
  const [clickLoading, setClickLoading] = useState(false);
  const [openInterestFields, setOpenInterestFields] = useState(false);
  const [openAwarenessChannels, setOpenAwarenessChannels] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const location = useLocation();

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getValidationSchema(true)),
    defaultValues: {
      role: "",
      studyYear: "",
      jobState: "",
      position: "",
      interestFields: [],
      where_you_know_us: [],
      otherAwarenessChannel: "",
    },
    mode: "onChange",
  });

  const role = watch("role");
  const position = watch("position");
  const studyYear = watch("studyYear");
  const whereYouKnowUs = watch("where_you_know_us");
  const isOtherSelected = whereYouKnowUs?.includes("Other");

  const onSubmit = async (formData) => {
    setClickLoading(true);

    // Combine where_you_know_us and otherAwarenessChannel
    let finalWhereYouKnowUs = [
      ...formData.where_you_know_us,
      ...(formData.otherAwarenessChannel
        ? [formData.otherAwarenessChannel]
        : []),
    ];
    finalWhereYouKnowUs = finalWhereYouKnowUs.filter(
      (value) => value !== "Other"
    );

    // Prepare the data to be submitted
    const updatedFormData = {
      ...formData,
      where_you_know_us: finalWhereYouKnowUs,
    };
    const requestBody = {
      ...roleData(updatedFormData),
      avatar: user.picture,
      auth0Id: user.sub,
      email: user.email,
    };
    try {
      const accessToken = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${accessToken}` };
      const userFilter = { auth0Id: user.sub };

      const { data } = await users.usersGet(1, 0, userFilter, { headers });
      if (data.data.length === 0) {
        await users.usersPost(requestBody, { headers });
      } else {
        await users.usersPatch(requestBody, userFilter, { headers });
      }

      toast.success("Success Sign Up");
      setTimeout(
        () =>
          loginWithRedirect({
            appState: { returnTo: location.pathname },
          }),
        2000
      );
    } catch (err) {
      const message = handleErrorMessage(
        err.response?.data?.message || err.message
      );
      toast.error(message);
      console.error("Error in updating user data", err);
    } finally {
      setClickLoading(false);
    }
  };

  const handleContinueToStep2 = async (e) => {
    e.preventDefault();
    const isValid = await trigger([
      "name",
      "whatsAppPhoneNumber",
      "role",
      "nickname",
      "position",
      "githubHandle",
      "behanceProfileLink",
      "terms",
    ]);
    if (isValid) setCurrentStep(1);
  };

  const handleSkipStep2 = (e) => {
    e.preventDefault();
    handleSubmit((formData) => onSubmit({ ...formData, skipStep2: true }))();
  };

  const onLogout = () => {
    logout({
      logoutParams: {
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
        returnTo: process.env.REACT_APP_BASE_URL,
      },
    });
  };

  const handleWhereYouKnowUsChange = (event) => {
    const value = event.target.value;
    const currentValues = watch("where_you_know_us") || [];
    let newWhereYouKnowUs;

    if (event.target.checked) {
      newWhereYouKnowUs = [...currentValues, value];
    } else {
      newWhereYouKnowUs = currentValues.filter((field) => field !== value);
    }

    setValue("where_you_know_us", newWhereYouKnowUs); // Update form value
  };

  // Handle click outside for dropdowns
  const dropdownRef = useRef(null);
  const awarenessChannelsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenInterestFields(false);
      }
      if (
        awarenessChannelsRef.current &&
        !awarenessChannelsRef.current.contains(event.target)
      ) {
        setOpenAwarenessChannels(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (user.role) {
    loginWithRedirect();
    navigate("/quest-list");
    return null;
  }

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div
            className="authincation-content style-2"
            style={{ maxWidth: "47rem" }}
          >
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content position-relative">
                <div
                  id="sign-in"
                  className="auth-form form-validation bg-white"
                  style={{ borderRadius: "2%", fontSize: "11px" }}
                >
                  <div
                    style={{ height: "60px" }}
                    className="mb-3 d-flex justify-content-between align-items-center"
                  >
                    <Button
                      variant="btn-rounded"
                      className="p-0 fs-4"
                      onClick={() =>
                        setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev))
                      }
                    >
                      <i
                        className={`fa-solid fa-chevron-left cursor-pointer ${
                          currentStep <= 0 ? "text-white" : "text-primary"
                        }`}
                      ></i>
                    </Button>
                    <img src={logo} alt="logo" className="h-100" />
                    <button
                      type="button"
                      onClick={onLogout}
                      className="btn-close"
                      style={{ height: "2em", fontSize: "18px" }}
                    ></button>
                  </div>

                  <form className="form-validate form-wizard">
                    <div
                      className="d-flex gap-4 justify-content-center"
                      style={{ margin: "30px 0 40px 0" }}
                    >
                      <div
                        className="bg-primary"
                        style={{
                          height: "8px",
                          width: "120px",
                          borderRadius: "100px",
                        }}
                      ></div>
                      <div
                        className={`${
                          currentStep === 1 ? "bg-primary" : "bg-light"
                        }`}
                        style={{
                          height: "8px",
                          width: "120px",
                          borderRadius: "100px",
                        }}
                      ></div>
                    </div>

                    {currentStep === 0 && (
                      <>
                        {/* Step 1 Fields */}
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label className="form-label font-w600">
                              Name{" "}
                              <span className="text-danger scale5 ms-2">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control mb-1"
                              placeholder="Your Name"
                              {...register("name")}
                            />
                            {errors.name && (
                              <div className="text-danger fs-12">
                                {errors.name.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="form-label font-w600">
                              Whatsapp Phone Number{" "}
                              <span className="text-danger scale5 ms-2">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control mb-1"
                              placeholder="Type Your Whatsapp Phone"
                              {...register("whatsAppPhoneNumber")}
                            />
                            {errors.whatsAppPhoneNumber && (
                              <div className="text-danger fs-12">
                                {errors.whatsAppPhoneNumber.message}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Role Selection */}
                        <div className="form-group mb-3">
                          <label className="form-label font-w600">
                            Role{" "}
                            <span className="text-danger scale5 ms-2">*</span>
                          </label>
                          <select
                            className="form-select form-control solid mb-1"
                            {...register("role")}
                            defaultValue={""}
                          >
                            <option value="" disabled>
                              Choose...
                            </option>
                            {ROLES.map((role, index) => (
                              <option key={index} value={role}>
                                {role}
                              </option>
                            ))}
                          </select>
                          {errors.role && (
                            <div className="text-danger fs-12">
                              {errors.role.message}
                            </div>
                          )}
                        </div>

                        {/* Conditional Fields Based on Role */}
                        {role && role !== "client" && (
                          <>
                            <div className="form-group mb-3">
                              <label className="form-label font-w600">
                                Nickname{" "}
                                <span className="text-danger scale5 ms-2">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control mb-1"
                                placeholder="Type Your Nickname"
                                {...register("nickname")}
                              />
                              {errors.nickname && (
                                <div className="text-danger fs-12">
                                  {errors.nickname.message}
                                </div>
                              )}
                            </div>

                            <div className="row">
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label font-w600">
                                  Designer OR Developer{" "}
                                  <span className="text-danger scale5 ms-2">
                                    *
                                  </span>
                                </label>
                                <select
                                  className="form-select form-control solid mb-1"
                                  {...register("position")}
                                  defaultValue={""}
                                >
                                  <option value="" disabled>
                                    Choose...
                                  </option>
                                  {POSITIONS.map((position, index) => (
                                    <option key={index} value={position}>
                                      {position}
                                    </option>
                                  ))}
                                </select>
                                {errors.position && (
                                  <div className="text-danger fs-12">
                                    {errors.position.message}
                                  </div>
                                )}
                              </div>

                              {position === "Developer" && (
                                <div className="form-group mb-3 col-md-6">
                                  <label className="form-label font-w600">
                                    Github Handle{" "}
                                    <span className="text-danger scale5 ms-2">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control mb-1"
                                    placeholder="Link"
                                    {...register("githubHandle")}
                                  />
                                  {errors.githubHandle && (
                                    <div className="text-danger fs-12">
                                      {errors.githubHandle.message}
                                    </div>
                                  )}
                                </div>
                              )}

                              {position === "Designer" && (
                                <div className="form-group mb-3 col-md-6">
                                  <label className="form-label font-w600">
                                    Behance{" "}
                                    <span className="text-danger scale5 ms-2">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control mb-1"
                                    placeholder="Link"
                                    {...register("behanceProfileLink")}
                                  />
                                  {errors.behanceProfileLink && (
                                    <div className="text-danger fs-12">
                                      {errors.behanceProfileLink.message}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </>
                        )}

                        {/* Terms and Conditions */}
                        <div className="form-check mx-1">
                          <input
                            type="checkbox"
                            id="terms"
                            className="form-check-input"
                            {...register("terms")}
                          />
                          <label htmlFor="terms" className="form-check-label">
                            By signing up, you agree to{" "}
                            <a
                              href="https://code-quests.com/privacy-policy-2/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary"
                            >
                              the terms of service
                            </a>{" "}
                            of code-quests.com
                          </label>
                          {errors.terms && (
                            <div className="text-danger fs-12">
                              {errors.terms.message}
                            </div>
                          )}
                        </div>

                        {/* Continue Button */}
                        <div className="text-center form-group mt-4">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={handleContinueToStep2}
                            disabled={clickLoading}
                          >
                            {clickLoading ? "Loading..." : "Continue"}
                          </button>
                        </div>
                      </>
                    )}

                    {currentStep === 1 && (
                      <>
                        {/* Step 2 Fields */}
                        <div className={role !== "client" ? "row" : ""}>
                          <div
                            className={`form-group mb-3 ${
                              role !== "client" ? "col-md-6" : ""
                            }`}
                          >
                            <label className="form-label font-w600">
                              Interest Fields
                            </label>
                            <div
                              className="form-control d-flex align-items-center mb-1"
                              onClick={() =>
                                setOpenInterestFields(!openInterestFields)
                              }
                            >
                              {watch("interestFields").length === 0
                                ? "Choose..."
                                : watch("interestFields").join(", ")}
                            </div>
                            {openInterestFields && (
                              <div
                                className="form-control h-100 p-4"
                                ref={dropdownRef}
                              >
                                {FIELDS_INTEREST.map((field, index) => (
                                  <div key={index} className="form-check">
                                    <input
                                      type="checkbox"
                                      value={field}
                                      id={`field-${index}`}
                                      className="form-check-input"
                                      checked={watch("interestFields").includes(
                                        field
                                      )}
                                      {...register("interestFields")}
                                    />
                                    <label
                                      htmlFor={`field-${index}`}
                                      className="form-check-label"
                                    >
                                      {field}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )}
                            {errors.interestFields && (
                              <div className="text-danger fs-12">
                                {errors.interestFields.message}
                              </div>
                            )}
                          </div>

                          {role !== "client" && (
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label font-w600">
                                Linkedin Profile
                              </label>
                              <input
                                type="text"
                                className="form-control mb-1"
                                placeholder="Link"
                                {...register("linkedinProfileLink")}
                              />
                              {errors.linkedinProfileLink && (
                                <div className="text-danger fs-12">
                                  {errors.linkedinProfileLink.message}
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        {/* Conditional Fields Based on Role and Study Year */}
                        {role === "contestant" && (
                          <div className="form-group mb-3">
                            <label className="form-label font-w600">
                              Study Year
                            </label>
                            <select
                              className="form-select form-control solid mb-1"
                              {...register("studyYear")}
                              defaultValue={""}
                            >
                              <option value="" disabled>
                                Choose...
                              </option>
                              {STUDY_YEARS.map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                            {errors.studyYear && (
                              <div className="text-danger fs-12">
                                {errors.studyYear.message}
                              </div>
                            )}
                          </div>
                        )}

                        {role === "contestant" &&
                          studyYear !== "Graduate" &&
                          studyYear !== "" && (
                            <div className="row">
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label font-w600">
                                  University
                                </label>
                                <input
                                  type="text"
                                  className="form-control mb-1"
                                  placeholder="Type Your University"
                                  {...register("university")}
                                />
                                {errors.university && (
                                  <div className="text-danger fs-12">
                                    {errors.university.message}
                                  </div>
                                )}
                              </div>

                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label font-w600">
                                  Faculty
                                </label>
                                <input
                                  type="text"
                                  className="form-control mb-1"
                                  placeholder="Type Your Faculty"
                                  {...register("faculty")}
                                />
                                {errors.faculty && (
                                  <div className="text-danger fs-12">
                                    {errors.faculty.message}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                        {/* Job State and Experience Fields */}
                        {(role === "contestant" && studyYear === "Graduate") ||
                        role !== "contestant" ? (
                          <div className={role !== "client" ? "row" : ""}>
                            <div
                              className={`form-group mb-3 ${
                                role !== "client" ? "col-md-4" : ""
                              }`}
                            >
                              <label className="form-label font-w600">
                                Company Name
                              </label>
                              <input
                                type="text"
                                className="form-control mb-1"
                                placeholder="Type Your Company"
                                {...register("companyName")}
                              />
                              {errors.companyName && (
                                <div className="text-danger fs-12">
                                  {errors.companyName.message}
                                </div>
                              )}
                            </div>

                            {role !== "client" && (
                              <>
                                <div className="form-group mb-3 col-md-4">
                                  <label className="form-label font-w600">
                                    Experience Years
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control mb-1"
                                    placeholder="Experience Years"
                                    {...register("experienceYears")}
                                  />
                                  {errors.experienceYears && (
                                    <div className="text-danger fs-12">
                                      {errors.experienceYears.message}
                                    </div>
                                  )}
                                </div>

                                <div className="form-group mb-3 col-md-4">
                                  <label className="form-label font-w600">
                                    Job State
                                  </label>
                                  <select
                                    className="form-select form-control solid mb-1"
                                    {...register("jobState")}
                                    defaultValue={""}
                                  >
                                    <option value="" disabled>
                                      Choose...
                                    </option>
                                    {JOB_STATES.map((state, index) => (
                                      <option key={index} value={state}>
                                        {state}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.jobState && (
                                    <div className="text-danger fs-12">
                                      {errors.jobState.message}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        ) : null}

                        {/* Where You Know Us */}
                        <div className="form-group mb-3">
                          <label className="form-label font-w600">
                            Where You Know Us
                          </label>
                          <div
                            className="form-control d-flex align-items-center mb-1"
                            onClick={() =>
                              setOpenAwarenessChannels(!openAwarenessChannels)
                            }
                          >
                            {whereYouKnowUs.length === 0
                              ? "Choose..."
                              : whereYouKnowUs.join(", ")}
                          </div>
                          {openAwarenessChannels && (
                            <div
                              className="form-control h-100 p-4"
                              ref={awarenessChannelsRef}
                            >
                              {AWARENESS_CHANNELS.map((channel, index) => (
                                <div key={index} className="form-check">
                                  <input
                                    type="checkbox"
                                    value={channel}
                                    id={`channel-${index}`}
                                    className="form-check-input"
                                    checked={whereYouKnowUs?.includes(channel)}
                                    onChange={handleWhereYouKnowUsChange}
                                  />
                                  <label
                                    htmlFor={`channel-${index}`}
                                    className="form-check-label"
                                  >
                                    {channel}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                          {errors.where_you_know_us && (
                            <div className="text-danger fs-12">
                              {errors.where_you_know_us.message}
                            </div>
                          )}
                        </div>

                        {/* Other Awareness Channel */}
                        {isOtherSelected && (
                          <div className="form-group mb-3">
                            <label className="form-label font-w600">
                              Other{" "}
                              <span className="text-danger scale5 ms-2">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control mb-1"
                              placeholder="Other"
                              {...register("otherAwarenessChannel")}
                            />
                            {errors.otherAwarenessChannel && (
                              <div className="text-danger fs-12">
                                {errors.otherAwarenessChannel.message}
                              </div>
                            )}
                          </div>
                        )}

                        {/* Country and City Fields */}
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label className="form-label font-w600">
                              Country
                            </label>
                            <input
                              type="text"
                              className="form-control mb-1"
                              placeholder="Type Your Country"
                              {...register("country")}
                            />
                            {errors.country && (
                              <div className="text-danger fs-12">
                                {errors.country.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="form-label font-w600">City</label>
                            <input
                              type="text"
                              className="form-control mb-1"
                              placeholder="Type Your City"
                              {...register("city")}
                            />
                            {errors.city && (
                              <div className="text-danger fs-12">
                                {errors.city.message}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Skip and Continue Buttons */}
                        <div className="row">
                          <div className="form-group mt-4 col-md-6">
                            <button
                              className="btn btn-outline-primary btn-block"
                              onClick={handleSkipStep2}
                              disabled={clickLoading}
                            >
                              {clickLoading ? (
                                <>
                                  Loading
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    style={{ marginLeft: "5px" }}
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="sr-only">Loading...</span>
                                </>
                              ) : (
                                "Skip"
                              )}
                            </button>
                          </div>

                          <div className="form-group mt-4 col-md-6">
                            <button
                              className="btn btn-primary btn-block"
                              onClick={handleSubmit(onSubmit)}
                              disabled={clickLoading}
                            >
                              {clickLoading ? (
                                <>
                                  Loading
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    style={{ marginLeft: "5px" }}
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="sr-only">Loading...</span>
                                </>
                              ) : (
                                "Continue"
                              )}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ProfileData;
